<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        @click="$router.push({name:'home'})"
      >
        <h2 class="brand-text text-primary ml-1">
          {{ appName }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100  d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('Welcome to') }}  👋
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('Please sign-in to your account and start the adventure') }}
          </b-card-text>
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <b-form-group
              :label="$t('User Name')"
              label-for="login-user-name"
            >
              <b-form-input
                id="login-user-name"
                v-model="user_name"
                :state="ValidationUserName?false:null"
                name="login-user-name"
                placeholder="اسم المستخدم"
              />
              <small class="text-danger">{{ ValidationUserName }}</small>
            </b-form-group>

            <!-- forgot password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">{{ $t('Password') }}</label>
              </div>

              <b-input-group
                class="input-group-merge"
                :class="ValidationPassword ? 'is-invalid':null"
              >
                <b-form-input
                  id="login-password"
                  v-model="password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  :state="ValidationPassword?false:null"
                  name="login-password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ ValidationPassword }}</small>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                {{ $t('Remember Me') }}
              </b-form-checkbox>
            </b-form-group>

            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="primary"
              block
              @click="Login"
            >
              {{ $t('Sign in') }}
            </b-button>
          </b-form>
          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">
              {{ $t('---') }}
            </div>
          </div>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: false,
      password: 'كلمة المرور',
      user_name: 'اسم_المستحدم',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      ValidationErrors: '',
    }
  },
  computed: {
    ValidationPassword() {
      if (this.ValidationErrors.password) return this.ValidationErrors.password[0]
      return null
    },
    ValidationUserName() {
      if (this.ValidationErrors.user_name) return this.ValidationErrors.user_name[0]
      return null
    },
    appName() {
      // return $themeConfig.app.appName
      return this.$store.getters['app/appName']
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      return require('@/assets/images/logo/logo.jpeg')
      // if (this.$store.state.appConfig.layout.skin === 'dark') {
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
      //   return this.sideImg
      // }
      // return this.sideImg
    },
  },
  beforeCreate() {
    this.$http.get('/sanctum/csrf-cookie')
  },
  methods: {
    Login() {
      this.ValidationErrors = ''
      this.$store.dispatch('auth/Login', {
        user_name: this.user_name,
        password: this.password,
        remember_me: this.status,
      }).then(() => {
        this.$router.go(0)
      }).catch(error => {
        this.ValidationErrors = error.response.data.errors
      })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
